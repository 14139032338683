import { parseISO } from "date-fns";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";
import { getData } from "../feathers";

export function getTypeActivityObject(configurationsList: any, typeActivity: any) {
	return configurationsList.ActivitiesInclusionOfficeTypeList.find((e: any) => e.alias === typeActivity) || { display: 'n.n.' }
}

export async function getActivityInclusionOffice(configurationsList: any, activityId: any) {
	return await getData(ENUM_SERVICES.ACTIVITIESINCLUSIONOFFICE, activityId).then((activity) => {
		activity.date = parseISO(activity.date);
		activity.relatedUsers = activity.relatedUsers.map((relatedUser: any) => {
			relatedUser.roleType = relatedUser.roleType ? configurationsList.CaseRoleList.find((e: any) => e.alias === relatedUser.roleType) : null;
			return relatedUser;
		});
		return activity
	})
}