
import { startOfDay } from 'date-fns';
import { Checkbox } from 'primereact/checkbox';
import { useEffect, useState } from "react";
import { CalendarChangesChecked } from '../Components/CalendarChangesChecked';
import { EditorChangesChecked } from '../Components/EditorChangesChecked';
import { DropdownChangesChecked } from '../Components/FormElements/DropdownChangesChecked';

import { InputChangesChecked } from '../Components/InputChangesChecked';
import { InputNumberChecked } from '../Components/InputNumberChecked';
import { useConfigurationsList } from "../Persistence/ConfigurationsContext";
import { useCurrentUser, useCurrentUserIsReaderEAA } from '../Persistence/CurrentUserContext';
import { logDefault } from '../Utils/logger';

export const defaultActivity = { id: null, version: 1, active: 1, name: '', date: startOfDay(new Date()), endDate: null, typeActivity: 'COOPERATION_MEETINGS', description: '', recurring: false, participants: 0, userId: null }
export const hasParticipants = (value) => [].includes(value)

const CLASSNAME = 'ActivitiesInclusionOfficeForm'
export const ActivitiesInclusionOfficeForm = ({ formik }) => {
	const isReader = useCurrentUserIsReaderEAA();
	const currentUser = useCurrentUser();
	const configurationsList = useConfigurationsList();
	const [readonly, setReadonly] = useState(false);
	const [mounted, setMounted] = useState(false)

	useEffect(() => {
		logDefault(CLASSNAME, 'info', 'formik values:', formik.values)
		setReadonly(isReader);
		setMounted(true)
	}, [isReader, formik.values])

	const onTypeActivityChange = (value) => {
		switch (value) {
			case 'COOPERATION_MEETINGS':
			case 'REGIONALCONFERENCES':
			case 'QUALIFICATIONMEASURES':
			case 'PUBLICRELATIONSMEASURES':
				formik.setFieldValue('participants', 0);
				break;
			default: onRecurringChange(false);
		}
	}

	const onRecurringChange = (value) => {
		logDefault(CLASSNAME, 'info', 'onRecurringChange:', value)
		if (!value) {
			formik.setFieldValue('endDate', null);
		}
		formik.setFieldValue('recurring', value);
	}

	const getSpecialComponent = (typeActivity) => {
		logDefault(CLASSNAME, 'info', 'getSpecialComponent:', typeActivity)
		const component = <div className='flex formgrid grid col-6'>
			<div className='field mr-6 col-2'>
				<label className='block' htmlFor="recurring">Wiederkehrend</label>
				<Checkbox inputId="recurring" checked={formik.values.recurring} onChange={e => onRecurringChange(e.checked)} />
			</div>
			<CalendarChangesChecked id='endDate' label='Wiederkehrend bis' formik={formik} key='endDate' disabled={!formik.values.recurring} className={'field col-fixed w-11rem'} classNameCalendar=' w-full' autoFocus={true} />
		</div>
		return typeActivity === 'PUBLICRELATIONSMEASURES' ? component : ''
	}

	return (
		<div className='provider-activities-form col' >
			{!formik ? '' : <div className="formgrid grid">
				<div className="field col  mb-0">
					<div className="formgrid grid">
						<InputChangesChecked id='name' type="text" formik={formik} editmode={true} label='Name der Aktivität' />
					</div>
					<div className="formgrid grid">
						<DropdownChangesChecked id='typeActivity' editmode={true} formik={formik} itemLabel='display' itemValue='alias' label={'Typ'} onChange={onTypeActivityChange} options={configurationsList.ActivitiesInclusionOfficeTypeList.filter(e => !e.isHeadline)} className='h-3rem line-height-4 col-6' />
						{getSpecialComponent(formik.values.typeActivity)}
					</div>
					<div className="formgrid grid">
						<CalendarChangesChecked id='date' label='Datum der Aktivität' formik={formik} key='date' className='field col-6 mr-3' classNameCalendar='w-10rem w-full' autoFocus={true} />
					</div>
					<div className="formgrid grid">
						{!mounted ? '' : <EditorChangesChecked id='description' value={formik.values.description} setValue={(value) => formik.setFieldValue('description', value)} label='Beschreibung der Aktivität' required={false} />}
					</div>
				</div>
			</div>}
		</div>

	)
}


export const validateActivity = (data) => {
	let errors = {};

	if (!data.typeActivity) {
		errors.typeActivity = 'Der Aktivitätentyp muss gesetzt sein!';
	}
	if (!data.date) {
		errors.date = 'Datum muss gesetzt sein!';
	}
	if (!data.name) {
		errors.name = 'Der Name der Aktivität muss gesetzt sein!';
	}
	if (data.recurring && !data.endDate) {
		errors.endDate = 'Wiederkehrend bis muss eingegeben werden!';
	}
	logDefault(CLASSNAME, 'info', 'validateActivity:', data)
	return errors;
}