import { useEffect, useState } from "react";
import { DropdownChangesChecked } from '../Components/DropdownChangesChecked';
import { useConfigurationsList } from "../Persistence/ConfigurationsContext";
import { useCaseRequestTypesList, useCaseRequestTypesSelectionList } from '../Persistence/CaseRequestTypesContext';
import { InputField } from '../Components/InputField';
import { CalendarChangesChecked } from '../Components/CalendarChangesChecked';
import { Dropdown } from "primereact/dropdown";

export const emptyCaseRequest = { id: null, version: 1, active: 1, userId: null, eaaCaseId: null, completionDate: null, requestDate: new Date(), status: 'INPROGRESS', caseRequestTypeId: 0 }
export const getEmptyCaseRequest = (eaaCaseId, userId) => { const value = JSON.parse(JSON.stringify(emptyCaseRequest)); return { ...value, requestDate: new Date(), userId, eaaCaseId } }

export const CaseRequestForm = ({ formik }) => {

	const configurationsList = useConfigurationsList();
	const caseRequestTypeList = useCaseRequestTypesList();
	const caseRequestTypeSelectionList = useCaseRequestTypesSelectionList();
	const [requestOptions, setRequestOptions] = useState([]);

	useEffect(() => {
		const requestOptions = JSON.parse(JSON.stringify(caseRequestTypeSelectionList))

		if (formik.values.caseRequestTypeId) {
			const currentCaseRequestType = caseRequestTypeList.find((e) => e.id === formik.values.caseRequestTypeId)
			if (!requestOptions.find((e) => e.id === formik.values.caseRequestTypeId)) {
				requestOptions.push(currentCaseRequestType)
			}
		}
		setRequestOptions(requestOptions)
	}, [caseRequestTypeSelectionList])

	return (<div className='mr-3'>
		<div className="formgrid grid">
			<InputField formik={formik} id='name' label='Name' type='text' />
		</div>
		<div className="formgrid grid">
			<DropdownChangesChecked editmode={true} formik={formik} id='caseRequestTypeId' itemLabel='name' label='Art des Antrags' options={requestOptions} optionValue='id' key='caserequesttype' />
			<CalendarChangesChecked className=' ' id='requestDate' formik={formik} label='Datum der Antragsstellung' />
		</div>
		<div className="formgrid grid">
			<div className='field col mr-3'>
				<label htmlFor="status">Status</label>
				<Dropdown id="status" name="status" optionLabel="display" optionValue="alias" value={formik.values.status} options={configurationsList.CaseRequestStatusList} onChange={(e) => { formik.setFieldValue('status', e.value); if (e.value === 'INPROGRESS') { formik.setFieldValue('completionDate', null) } }} autoFocus className={'bg-white w-full'} />
			</div>
			{formik.values.status === 'INPROGRESS' ? <div className="field col"></div> :
				<CalendarChangesChecked disabled={formik.values.status === 'INPROGRESS'} className=' ' id='completionDate' formik={formik} label={formik.values.status === 'RETRACTED' ? 'Datum der Rücknahme' : 'Datum des Bescheids'} />}
		</div>
		<div className="formgrid grid">
			<InputField formik={formik} id='description' label='Kurzbeschreibung' type='text' />
		</div>
	</div>
	)
}

export const validateCaseRequest = (data) => {
	let errors = {};
	if (!data.name) {
		errors.name = 'Name ist ein Pflichtfeld';
	}
	if (!data.caseRequestTypeId) {
		errors.caseRequestTypeId = 'Antragsart ist ein Pflichtfeld';
	}
	if (!data.requestDate) {
		errors.requestDate = 'Datum der Antragsstellung ist ein Pflichtfeld';
	}
	if (data.status !== 'INPROGRESS' && data.status !== 'RETRACTED' && !data.completionDate) {
		errors.completionDate = 'Datum des Bescheids ist ein Pflichtfeld';
	}


	return errors;
}