import { Checkbox } from "primereact/checkbox"
import { InputField } from "../Components/InputField"
import { InputNumberChecked } from "../Components/InputNumberChecked"
import { InputChangesChecked } from "../Components/InputChangesChecked"
import { useConfigurationsList } from "../Persistence/ConfigurationsContext"

export const StatisticForm = ({ formik }) => {
	const configurationsList = useConfigurationsList();
	return (< div className='col p-0 pr-6 w-full'>
		<div className="formgrid grid w-full">
			<InputChangesChecked
				autoFocus={true}
				formik={formik}
				id='Integrationsamt'
				label='Integrations-/Inklusionsamt:'
				type='text'
				col='w-full m-2 grid'
				rowStyle={true}
				colLabels='col'
				className=' w-27rem col-fixed'
			/>
		</div>

		{configurationsList.eaaModules.ACTIVITIES_INCLUSIONOFFICE === '1' ? '' :
			<>
				<div className="formgrid w-full m-2 font-bold">
					<label>4. Aktivitäten der Integrations-/Inklusionsämter zu den EA</label>
				</div>
				<div className='mb-4 px-2 bg-gray-50'>
					<div className="flex justify-content-end w-full">
						<label className='my-1 mx-3'>Anzahl</label>
					</div>
					<div className="formgrid grid w-full">
						<InputNumberChecked
							autoFocus={true}
							formik={formik}
							id='A1'
							label='Durchgeführte Netzwerktreffen mit EA-Träger/Berater/innen'
							type='text'
							colLabels='col' rowStyle={true}
							col='w-full m-2 grid'
							className='w-6rem col-fixed mr-1' digits={0} max={999999999} />
					</div>
					<div className="formgrid grid w-full">
						<InputNumberChecked autoFocus={true} colLabels='col' rowStyle={true} formik={formik} id='A2' label='Durchgeführte Regio-Tagungen mit allen Akteuren' type='text' col='w-full m-2 grid' className=' w-6rem col-fixed mr-1' digits={0} max={999999999} />
					</div>
					<div className="formgrid grid w-full">
						<InputNumberChecked autoFocus={true} colLabels='col' rowStyle={true} formik={formik} id='A3' label='Angebotene Qualifizierungsmaßnahmen' type='text' col='w-full m-2 grid' className=' w-6rem col-fixed mr-1' digits={0} max={999999999} />
					</div>
					<div className="formgrid grid w-full">
						<InputNumberChecked autoFocus={true} colLabels='col' rowStyle={true} formik={formik} id='A4' label='Öffentlichkeitsarbeitsmaßnahmen Werbung für die EA' type='text' col='w-full m-2 grid' className=' w-6rem col-fixed mr-1' digits={0} max={999999999} />
					</div>
				</div>
			</>}
		<div className="formgrid w-full m-2 font-bold">
			<label>5. Finanzierung der Einheitlichen Ansprechstellen durch das Integrations-/Inklusionsamt</label>
		</div>
		<div className='px-2 bg-gray-50'>
			<div className="flex justify-content-end w-full">
				<label className='my-1 mx-3'>Euro</label>
			</div>
			<div className="formgrid grid w-full">
				<InputNumberChecked autoFocus={true} colLabels='col' rowStyle={true} formik={formik} id='F1' label='Personal- und Sachkosten der Träger' type='text' col='w-full m-2 grid' className=' w-6rem col-fixed mr-1' digits={0} max={999999999} />
			</div>
			<div className="formgrid grid w-full">
				<InputNumberChecked autoFocus={true} colLabels='col' rowStyle={true} formik={formik} id='F2' label='Kosten für die Öffentlichkeitsarbeit und Veranstaltungen (s. 4.)' type='text' col='w-full m-2 grid' className=' w-6rem col-fixed mr-1' digits={0} max={999999999} />
			</div>
			<div className="formgrid grid w-full">
				<InputNumberChecked autoFocus={true} colLabels='col' rowStyle={true} formik={formik} id='F3' label='Kosten für die Qualifizierung der EA' type='text' col='w-full m-2 grid' className=' w-6rem col-fixed mr-1' digits={0} max={999999999} />
			</div>
		</div>
		<div className="flex w-full m-2 p-2 justify-content-end">
			<label className='my-1 mx-3'>Im Format Excel:</label>
			<Checkbox onChange={e => formik.setFieldValue('asExcelSheet', e.checked)} checked={formik.values.asExcelSheet} />
		</div>
	</div>
	)
}
export const emptyStatistic = { Integrationsamt: '', A1: '', A2: '', A3: '', A4: '', F1: '', F2: '', F3: '', startDate: null, endDate: null, userID: null, organizationId: null, asExcelSheet: null }
export const validateStatistik = (data) => {
	let errors = {};
	return errors;
}
