import React, { useContext, useEffect, useState } from "react";
import { useCurrentUser } from "./CurrentUserContext";
import { useUserResponsibleList } from './UserContext';
import { logContext } from '../Utils/logger';
import { patchData } from '../feathers';
import { ENUM_SERVICES } from '../Enums/ENUM_SERVICES';
import { parseISO } from 'date-fns';
import { ENUM_DATAVIEWS_CONFIG, getDefaultFilter, getDefaultPagination, getDefaultSort } from '../Enums/ENUM_DATAVIEWS_CONFIG'
import { useConfigurationsList } from "./ConfigurationsContext";
import { copyObject } from "../snippets";

const CLASSNAME = 'DataViewFilterProvider';

const DataViewMountedContext = React.createContext();
const DataViewFilterContext = React.createContext();
const DataViewSortContext = React.createContext();
const DataViewFilterUpdateContext = React.createContext();
const DataViewSortUpdateContext = React.createContext();
const DataViewPaginationContext = React.createContext();
const DataViewPaginationUpdateContext = React.createContext();
const DataViewScrollContext = React.createContext();
const DataViewScrollUpdateContext = React.createContext();

export function useDataViewMounted() {
	return useContext(DataViewMountedContext);
}
export function useDataViewFilter() {
	return useContext(DataViewFilterContext);
}
export function useDataViewFilterUpdate() {
	return useContext(DataViewFilterUpdateContext);
}
export function useDataViewSort() {
	return useContext(DataViewSortContext);
}
export function useDataViewSortUpdate() {
	return useContext(DataViewSortUpdateContext);
}
export function useDataViewPagination() {
	return useContext(DataViewPaginationContext);
}
export function useDataViewPaginationUpdate() {
	return useContext(DataViewPaginationUpdateContext);
}
export function useDataViewScroll() {
	return useContext(DataViewScrollContext);
}
export function useDataViewScrollUpdate() {
	return useContext(DataViewScrollUpdateContext);
}

export function DataViewFilterProvider({ children }) {
	const configurationsList = useConfigurationsList();
	const currentUser = useCurrentUser();
	const userList = useUserResponsibleList();
	const [mounted, setMounted] = useState(false);

	const [dataViewFilter, setDataViewFilter] = useState([]);
	const [dataViewSort, setDataViewSort] = useState([]);
	const [dataViewPagination, setDataViewPagination] = useState([]);
	const [dataViewScroll, setDataViewScroll] = useState([]);

	useEffect(() => {
		if (configurationsList && configurationsList.DataviewsConfig && currentUser) {
			setDataViewFilter(getDefaultFilter(undefined, configurationsList.DataviewsConfig))
			setDataViewSort(getDefaultSort(undefined, configurationsList.DataviewsConfig))
			setDataViewPagination(getDefaultPagination(undefined, configurationsList.DataviewsConfig))
		}

	}, [configurationsList, currentUser])

	const setDataViewValues = (data, settings) => {
		const settingKeys = Object.keys(settings);
		for (let dataKey of settingKeys) {
			for (let key of Object.keys(settings[dataKey])) {
				const entry = settings[dataKey][key];
				if (entry.instance === 'DATE' && entry.maxDate) {
					settings[dataKey][key].maxDate = parseISO(settings[dataKey][key].maxDate)
				}
				if (entry.instance === 'DATE' && entry.value) {
					settings[dataKey][key].maxDate = parseISO(settings[dataKey][key].value)
				}
			}
		}

		const dataKeys = Object.keys(data);

		for (let dataKey of dataKeys) {
			if (settingKeys.includes(dataKey)) {
				data[dataKey] = settings[dataKey].maxDate ? { ...settings[dataKey], maxDate: new Date() } : settings[dataKey]
			}
		}
	}

	useEffect(() => {
		if (userList && userList.length !== 0 && currentUser && dataViewFilter) {

			if (currentUser?.settings?.updatedBy !== null && currentUser.id !== currentUser?.settings?.updatedBy) {
				console.error('********************** FALSCHE User Settings currentUser id = ' + currentUser.id + ' -> updatedBy = ' + currentUser?.settings?.updatedBy)
			}

			if (currentUser?.settings?.settingId && currentUser.id !== currentUser?.settings?.settingId) {
				console.error('********************** FALSCHE User Settings currentUser id = ' + currentUser.id + ' -> settingId = ' + currentUser?.settings?.settingId)
			}
			if (currentUser.settings && currentUser.settings.dataViewFilter) {
				for (let entry of Object.keys(dataViewFilter)) {
					const filter = dataViewFilter[entry]
					for (let field of Object.keys(filter)) {
						if (currentUser.settings.dataViewFilter[entry] && currentUser.settings.dataViewFilter[entry][field]) {
							dataViewFilter[entry][field].value = currentUser.settings.dataViewFilter[entry][field].value
						}
					}
				}
				for (let entry of Object.keys(configurationsList.DataviewsConfig)) {
					const filter = configurationsList.DataviewsConfig[entry].filters
					const filtername = configurationsList.DataviewsConfig[entry].alias;
					for (let field of Object.keys(filter)) {
						if (currentUser.settings.dataViewFilter[filtername] && currentUser.settings.dataViewFilter[filtername][field]) {
							filter[field].value = currentUser.settings.dataViewFilter[filtername][field].value
						}
					}
					if (currentUser.settings.dataViewSort[filtername]) {
						configurationsList.DataviewsConfig[entry].sort = currentUser.settings.dataViewSort[filtername]
					}
					if (currentUser.settings.dataViewPagination[filtername]) {
						configurationsList.DataviewsConfig[entry].pagination = currentUser.settings.dataViewPagination[filtername]
					}
				}
				//setDataViewValues(dataViewFilter, currentUser.settings.dataViewFilter);
				setDataViewValues(dataViewSort, currentUser.settings.dataViewSort);
				setDataViewValues(dataViewPagination, currentUser.settings.dataViewPagination);
			} else {
				console.error('********************** User Settings sind LEER')
				const user = userList.find(e => e.id === currentUser.id);
				dataViewFilter[ENUM_DATAVIEWS_CONFIG.EAACASES]['col_02'].value = user ? [user.id] : [];
				patchData(ENUM_SERVICES.USERSETTINGS, { id: (currentUser.settings ? currentUser.settings.id : null), dataViewFilter, dataViewSort, dataViewPagination, settingId: currentUser.id }).then((settings) => {
					currentUser.settings = settings
				})
			}

			setMounted(true);
		}
	}, [currentUser, userList])

	const setScroll = async (DATAVIEW_CONFIG_ENTRY, value) => {
		const dataviewScroll = copyObject(dataviewScroll)
		dataviewScroll[DATAVIEW_CONFIG_ENTRY] = value
		setDataViewScroll(dataviewScroll)
	}
	const setFilter = async (DATAVIEW_CONFIG_ENTRY, value) => {
		const filter = copyObject(dataViewFilter)
		const filtername = DATAVIEW_CONFIG_ENTRY.alias || DATAVIEW_CONFIG_ENTRY
		filter[filtername] = value;
		logContext(CLASSNAME, 'info', 'setFilter: ' + filtername, value);
		await patchData(ENUM_SERVICES.USERSETTINGS, { id: currentUser.settings.id, method: 'setFilter', DATAVIEW_CONFIG_ENTRY, dataViewFilter: filter, settingId: currentUser.id, updatedBy: currentUser.id }).then((userSettings) => {
			const resultFilter = JSON.parse(userSettings.dataViewFilter)
			logContext(CLASSNAME, 'info', 'setFilter result: ' + filtername, resultFilter[filtername]);
		})
		setDataViewFilter(filter)
	}
	const setSort = async (DATAVIEW_CONFIG_ENTRY, value) => {
		const sortItem = copyObject(dataViewSort)
		sortItem[DATAVIEW_CONFIG_ENTRY.alias || DATAVIEW_CONFIG_ENTRY] = value;
		logContext(CLASSNAME, 'info', 'setSort:' + DATAVIEW_CONFIG_ENTRY, value)
		await patchData(ENUM_SERVICES.USERSETTINGS, { id: currentUser.settings.id, method: 'setSort', DATAVIEW_CONFIG_ENTRY, dataViewSort: sortItem, settingId: currentUser.id });
		setDataViewSort(sortItem)
	}

	const setPagination = async (DATAVIEW_CONFIG_ENTRY, value) => {
		const pagination = copyObject(dataViewPagination)
		pagination[DATAVIEW_CONFIG_ENTRY.alias || DATAVIEW_CONFIG_ENTRY] = value;
		logContext(CLASSNAME, 'info', 'setPagination:' + DATAVIEW_CONFIG_ENTRY, value)
		await patchData(ENUM_SERVICES.USERSETTINGS, { id: currentUser.settings.id, method: 'setPagination', DATAVIEW_CONFIG_ENTRY, dataViewPagination: pagination, settingId: currentUser.id });
		setDataViewPagination(pagination)
	}

	return (
		<DataViewMountedContext.Provider value={mounted}>
			<DataViewFilterContext.Provider value={dataViewFilter}>
				<DataViewSortContext.Provider value={dataViewSort}>
					<DataViewPaginationContext.Provider value={dataViewPagination}>
						<DataViewFilterUpdateContext.Provider value={setFilter}>
							<DataViewSortUpdateContext.Provider value={setSort}>
								<DataViewPaginationUpdateContext.Provider value={setPagination}>
									<DataViewScrollContext.Provider value={dataViewScroll}>
										<DataViewScrollUpdateContext.Provider value={setScroll}>
											{children}
										</DataViewScrollUpdateContext.Provider>
									</DataViewScrollContext.Provider>
								</DataViewPaginationUpdateContext.Provider>
							</DataViewSortUpdateContext.Provider>
						</DataViewFilterUpdateContext.Provider>
					</DataViewPaginationContext.Provider>
				</DataViewSortContext.Provider>
			</DataViewFilterContext.Provider>
		</DataViewMountedContext.Provider>
	)
}
