import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";

export const REMINDERSERVICECONFIG = {
	[ENUM_SERVICES.EAACASES]: { servicename: ENUM_SERVICES.CASEUSERS, displayname: 'Falls', header: 'Fall' },
	[ENUM_SERVICES.COMPANIES]: { servicename: ENUM_SERVICES.COMPANYUSERS, displayname: 'Unternehmens', header: 'Unternehmen' },
	[ENUM_SERVICES.PROVIDERACTIVITIES]: { servicename: ENUM_SERVICES.PROVIDERACTIVITYUSERS, displayname: 'Trägeraktivität', header: 'Trägeraktivität' },
	[ENUM_SERVICES.JOBPROFILES]: { servicename: ENUM_SERVICES.JOBPROFILES, displayname: 'Stellenprofils', header: 'Stellenprofil' },
	[ENUM_SERVICES.FBIBJOBPROFILES]: { servicename: ENUM_SERVICES.FBIBJOBPROFILES, displayname: 'Stellenprofils', header: 'Stellenprofil' },
	[ENUM_SERVICES.ASSOCIATEDORGANIZATIONS]: { servicename: ENUM_SERVICES.ASSOCIATEDORGANIZATIONS, displayname: 'Netzwerkkontakte', header: 'Netzwerkkontakt' },
	[ENUM_SERVICES.ASSOCIATEDCONTACTS]: { servicename: ENUM_SERVICES.ASSOCIATEDCONTACTS, displayname: 'Netzwerkkontakte', header: 'Netzwerkkontakt' },
	[ENUM_SERVICES.FBIBPROVIDERACTIVITIES]: { servicename: ENUM_SERVICES.FBIBPROVIDERACTIVITYUSERS, displayname: 'FBiB Trägeraktivität', header: 'FBiB Trägeraktivität' },
	[ENUM_SERVICES.FBIBCASES]: { servicename: ENUM_SERVICES.FBIBCASES, displayname: 'FBiB Falls', header: 'FBiB Fall' },
	[ENUM_SERVICES.TRAININGCENTERS]: { servicename: ENUM_SERVICES.TRAININGCENTERS, displayname: 'Ausbildungsstätte', header: 'Ausbildungsstätte' },
	[ENUM_SERVICES.TRAININGCOURSE]: { servicename: ENUM_SERVICES.TRAININGCOURSE, displayname: 'Ausbildungsgang', header: 'Ausbildungsgang' },
	[ENUM_SERVICES.ACTIVITIESINCLUSIONOFFICE]: { servicename: ENUM_SERVICES.ACTIVITIESINCLUSIONOFFICE, displayname: 'Aktivität InA', header: 'Aktivität InA' },
}

export const getEmptyReminder = (serviceName: String, serviceId: Number, userId: Number, name: String) => {
	const reminder = { id: null, description: '', date: new Date(), serviceName, serviceId, userId, name, reminded: 0 }
	return reminder;
}