import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { useState } from 'react';
import { EditDialog } from '../Components/Dialogs/EditDialog';
import { patchData } from '../feathers';
import { ENUM_ROUTES } from '../Navigation/Routes';
import { useBreadcrumbsAdd } from '../Persistence/Breadcrumbs';
import { useDataviewsConfig } from '../Persistence/ConfigurationsContext';
import { useCurrentUser, useCurrentUserIsReaderEAA } from '../Persistence/CurrentUserContext';
import { ActivitiesInclusionOfficeForm, defaultActivity, validateActivity } from './ActivitiesInclusionOfficeForm';
import { ENUM_DATAVIEWS_CONFIG } from '../Enums/ENUM_DATAVIEWS_CONFIG';
import { TemplateDataViewPagination } from '../Components/TemplateDataViewPagination';
import { ENUM_SERVICES } from '../Enums/ENUM_SERVICES';

const DATAVIEW_CONFIG = ENUM_DATAVIEWS_CONFIG.ACTIVITIES_INCLUSIONOFFICE;
export const ActivitiesInclusionOfficeDataview = () => {
	const getDataviewConfig = useDataviewsConfig();
	const [dataviewConfig, setDataviewConfig] = useState(getDataviewConfig(DATAVIEW_CONFIG));
	const addBreadcrumb = useBreadcrumbsAdd();
	const currentUser = useCurrentUser();
	const isReader = useCurrentUserIsReaderEAA();
	const [displayNewDialog, setDisplayNewDialog] = useState(false);

	const patchProviderActivity = async () => {
		await patchData(ENUM_SERVICES.ACTIVITIESINCLUSIONOFFICE, formik.values, currentUser).then((activity) => {
			addBreadcrumb(`${activity.name}`, `${ENUM_ROUTES.ACTIVITIES_INCLUSIONOFFICE}/` + activity.id);
		})
	}
	const renderResponsible = (rowData) => {
		return (rowData.responsible ? rowData.responsible.lastname + ', ' + rowData.responsible.firstname : 'n.n.')
	}
	const columns = [
		{ filterAlias: 'col_01', field: "displayId", style: { width: '110px' }, body: null },
		{ filterAlias: 'col_02', field: "name", style: { flexGrow: 1, flexBasis: '150px' }, body: null },
		{ filterAlias: 'col_03', field: "typeActivity", style: { flexGrow: 1, flexBasis: '200px' }, body: 'renderList' },
		{ filterAlias: 'col_04', field: "responsible", style: { flexGrow: 1, flexBasis: '150px' }, body: renderResponsible },
		{ filterAlias: 'col_05', field: "date", style: { width: '180px' }, body: 'renderDate', },
		{ filterAlias: 'col_06', field: "endDate", style: { width: '200px' }, body: 'renderDate' }
	]

	const formik = useFormik({
		initialValues: { ...defaultActivity, userId: currentUser.id },
		validate: validateActivity,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	return (
		<div className='mx-auto eaa-dataView select-none' style={{ maxWidth: 'calc(100vw - 100px)' }}>
			<EditDialog
				form={<ActivitiesInclusionOfficeForm formik={formik} key='activityInclusionofice-form' />}
				formik={formik}
				patchForm={patchProviderActivity}
				header={'Neue Aktivität anlegen'}
				message={'Hier können sie eine neue Aktivität des Inklusionsamts anlegen'}
				setDisplayDialog={setDisplayNewDialog}
				displayDialog={displayNewDialog}
			/>
			{!dataviewConfig ? '' : <TemplateDataViewPagination
				showOpenLinkColumn={true}
				key='datatable-activityinclusionoffice'
				scrollHeight={'calc(100vh - 260px)'}
				classNameDataTable='flex w-full'
				ROUTE={ENUM_ROUTES.ACTIVITIES_INCLUSIONOFFICE}
				buttons={[<Button
					key='button-new-activity'
					disabled={isReader}
					onClick={() => { formik.setValues({ ...defaultActivity, userId: currentUser.id }); setDisplayNewDialog(true) }}
					className='flex  text-white' label='Aktivität anlegen' />]}
				columns={columns}
				dataTableWidth='100%'
				dataviewConfig={dataviewConfig}
			/>}
		</div>
	)

}