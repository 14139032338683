import { parseISO } from "date-fns";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";
import client, { getData, patchData } from "../feathers";

const servicename = ENUM_SERVICES.EAACASES;

const getQueryFirstContact = (companyId: number) => {
	return `SELECT eaa_cases.id,case_events.id AS 'caseEventId', case_events.eventDate,MIN(case_events.eventDate) FROM eaa_cases
LEFT JOIN case_events on case_events.eaaCaseId = eaa_cases.id AND case_events.eventType IN ('CONTACT','CONTACTRESULT','SUSTAINABILITY')
WHERE eaa_cases.companyId = ${companyId}`
}

export async function getEAACase(eaaCaseId: any, configurationsList: any, isReader: boolean, setReadonly: Function, setEaaCase: Function, setToastParams: Function, setFavoriteContactPersons: Function) {
	return await getData(servicename, eaaCaseId).then(async (eaaCase) => {
		await client.service(ENUM_SERVICES.UTILS).create({ method: 'rawQuery', query: `SELECT count(eaa_cases.id) as count FROM eaa_cases WHERE eaa_cases.companyId = ${eaaCase.companyId}` }).then(async (result: any) => {
			eaaCase.countEaaCases = result[0].count
		})
		await client.service(ENUM_SERVICES.UTILS).create({ method: 'rawQuery', query: `SELECT count(fbib_cases.id) as count FROM fbib_cases WHERE fbib_cases.companyId = ${eaaCase.companyId}` }).then(async (result: any) => {
			eaaCase.countFBiBCases = result[0].count
		})
		eaaCase.date = parseISO(eaaCase.date)
		eaaCase.firstContact = false
		/** raw query to find firstContact */
		if (eaaCase.caseEvents && eaaCase.caseEvents.length > 0) {
			eaaCase.firstContact = await client.service('utils').create({ method: 'rawQuery', query: getQueryFirstContact(eaaCase.companyId) }).then((result: any) => {
				return result && result.length > 0 && (result[0].id === eaaCase.id)
			})
		}
		if (configurationsList) {
			eaaCase.G1 = 0
			eaaCase.typeCONTACT = 0
			eaaCase.typeCONTACTRESULT = 0
			eaaCase.typeSUSTAINABILITY = 0
			eaaCase.caseEvents = eaaCase.caseEvents.map((caseEvent: any) => {
				eaaCase.typeCONTACT = eaaCase.typeCONTACT + (caseEvent.eventType === 'CONTACT' ? 1 : 0)
				eaaCase.typeCONTACTRESULT = eaaCase.typeCONTACTRESULT + (caseEvent.eventType === 'CONTACTRESULT' ? 1 : 0)
				eaaCase.typeSUSTAINABILITY = eaaCase.typeSUSTAINABILITY + (caseEvent.eventType === 'SUSTAINABILITY' ? 1 : 0)
				if (caseEvent.caseEventClassifiers.find((e: any) => (e.caseEventClassifier === 'G1' && e.count === 1))) {
					eaaCase.G1 = eaaCase.G1 + 1
				}
				for (let caseEventClassifier of configurationsList.statistics.comparableCompanyVisit) {
					if (caseEvent.caseEventClassifiers.find((e: any) => (e.caseEventClassifier === caseEventClassifier && e.count === 1))) {
						eaaCase.G1 = eaaCase.G1 + 1
					}
				}

				caseEvent.eventType = caseEvent.eventType ? configurationsList.EventTypeList.find((e: any) => e.alias === caseEvent.eventType) : null;
				return caseEvent;
			});
			if (eaaCase.company && eaaCase.company.contactPersons) {
				eaaCase.company.contactPersons = eaaCase.company.contactPersons.map((contactPerson: any) => {
					contactPerson.contactType = configurationsList.ContactTypeList.find((e: any) => e.alias === contactPerson.contactType);
					return contactPerson;
				})
			}
			eaaCase.relatedUsers = []
			if (eaaCase.casePerson) {
				eaaCase.casePerson.gender = configurationsList.GenderTypeList.find((e: any) => e.alias === eaaCase.casePerson.gender)
			}
			eaaCase.caseRequests = eaaCase.caseRequests.map((caseRequest: any) => {
				caseRequest.requestDate = parseISO(caseRequest.requestDate);
				caseRequest.completionDate = caseRequest.completionDate ? parseISO(caseRequest.completionDate) : null;
				caseRequest.status = configurationsList.CaseRequestStatusList.find((status: any) => status.alias === caseRequest.status)
				return caseRequest;
			})
		}
		if (setReadonly) {
			setReadonly(((eaaCase.status.alias || eaaCase.status) === 'INACTIVE') || isReader);
		}
		if (setEaaCase) {
			setEaaCase(eaaCase);
		}
		if (setFavoriteContactPersons) {
			setFavoriteContactPersons(eaaCase.favoriteContactPersons || '')
		}
		return eaaCase
	}).catch((error) => {
		if (setToastParams) {
			setToastParams({ title: 'Daten konnten nicht ermittelt werden', message: error.message })
		} else {
			throw error
		}
	});
}

export async function patchEAACase(eaaCase: any, configurationsList: any, isReader: boolean, setReadonly: Function, setEaaCase: Function, setToastParams: Function, setFavoriteContactPersons: Function) {
	return await patchData(servicename, eaaCase).then((eaaCase) => {
		return getEAACase(eaaCase.id, configurationsList, isReader, setReadonly, setEaaCase, setToastParams, setFavoriteContactPersons)
	})
}