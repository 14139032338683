import { useBreadcrumbsAdd } from "../Persistence/Breadcrumbs";
import { useStrgPressed } from "../Persistence/StrgPressed";
import { ENUM_ICONS } from "./Icons";


export const OpenEye = ({ id, ROUTE, dataviewConfig, lastOpenId }) => {
	const addBreadcrumb = useBreadcrumbsAdd();
	const ctlPressed = useStrgPressed();
	const onClckLink = () => {
		if (dataviewConfig) {
			dataviewConfig.lastOpenId = lastOpenId
		}
		ctlPressed ? window.open(window.location.href + '/' + id, '_blank', { rel: "noopener noreferrer" }) : addBreadcrumb(``, `${ROUTE}/` + id);
	}

	return (
		<div className="flex justify-content-center w-full" onClick={onClckLink}>
			<i className={ENUM_ICONS.EYE} />
		</div>
	)
}