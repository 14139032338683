import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";
import client, { patchData } from "../feathers";
import { getSortedList } from "../snippets";

export type RELATEDUSERSERVICECONFIG_TYPE = {
	ACTIVITY_INCLUSIONOFFICE: RELATEDUSERSERVICECONFIG_ENTRY, CASE: RELATEDUSERSERVICECONFIG_ENTRY, COMPANY: RELATEDUSERSERVICECONFIG_ENTRY, PROVIDERACTIVITY: RELATEDUSERSERVICECONFIG_ENTRY, FBIBCASE: RELATEDUSERSERVICECONFIG_ENTRY, FBIBPROVIDERACTIVITY: RELATEDUSERSERVICECONFIG_ENTRY
}

export type RELATEDUSERSERVICECONFIG_ENTRY = {
	mainservice: ENUM_SERVICES,
	servicename: ENUM_SERVICES,
	idFieldname: string,
	displayname: string,
	deleteMessage: string,
	editMessage: string
}

export const RELATEDUSERSERVICECONFIG: RELATEDUSERSERVICECONFIG_TYPE = {
	CASE: { mainservice: ENUM_SERVICES.EAACASES, servicename: ENUM_SERVICES.CASEUSERS, idFieldname: 'eaaCaseId', displayname: 'Falls', deleteMessage: 'vom Fall', editMessage: 'im Fall' },
	COMPANY: { mainservice: ENUM_SERVICES.COMPANIES, servicename: ENUM_SERVICES.COMPANYUSERS, idFieldname: 'companyId', displayname: 'Unternehmens', deleteMessage: 'vom Unternehmen', editMessage: 'des Unternehmens' },
	PROVIDERACTIVITY: { mainservice: ENUM_SERVICES.PROVIDERACTIVITIES, servicename: ENUM_SERVICES.PROVIDERACTIVITYUSERS, idFieldname: 'provideractivityId', displayname: 'Trägeraktivität', deleteMessage: 'aus der Trägeraktivität', editMessage: 'der Trägeraktivität' },
	ACTIVITY_INCLUSIONOFFICE: { mainservice: ENUM_SERVICES.ACTIVITIESINCLUSIONOFFICE, servicename: ENUM_SERVICES.ACTIVITIESINCLUSIONOFFICEUSERS, idFieldname: 'activitiesInclusionOfficeId', displayname: 'Aktivität des Inklusionsamts', deleteMessage: 'aus der Aktivität des Inklusionsamts', editMessage: 'der Aktivität des Inklusionsamts' },
	FBIBCASE: { mainservice: ENUM_SERVICES.FBIBCASES, servicename: ENUM_SERVICES.FBIBCASEUSERS, idFieldname: 'fbibCaseId', displayname: 'FBiB Falls', deleteMessage: 'vom FBiB Fall', editMessage: 'im FBiB Fall' },
	FBIBPROVIDERACTIVITY: { mainservice: ENUM_SERVICES.FBIBPROVIDERACTIVITIES, servicename: ENUM_SERVICES.FBIBPROVIDERACTIVITYUSERS, idFieldname: 'fbibProvideractivityId', displayname: 'Trägeraktivität', deleteMessage: 'aus der Trägeraktivität', editMessage: 'der Trägeraktivität' },
}

export async function getRelatedUsersFromParent(relatedUserServcieConfig: RELATEDUSERSERVICECONFIG_ENTRY, parentId: number) {
	return await client.service(ENUM_SERVICES.UTILS).create({ method: 'findRelatedUsers', service: relatedUserServcieConfig.mainservice, serviceId: parentId }).then((result: any[]) => {
		return getSortedList(result, 'roleType', true)
	});
}

export async function addRelatedUser(relatedUserServiceConfig: RELATEDUSERSERVICECONFIG_ENTRY, parentId: number, relatedUser: any, setToastParams: Function, setRelatedUsers: Function) {
	await getRelatedUsersFromParent(relatedUserServiceConfig, parentId).then(async (relatedUsers: any[]) => {
		const existingRelatedUser: any = relatedUsers.find((e: any) => e.id === relatedUser.id)
		const relatedUserToPatch = {
			id: existingRelatedUser ? existingRelatedUser.relatedUserId : null,
			active: relatedUser.active,
			version: existingRelatedUser ? existingRelatedUser.version : 1,
			roleType: relatedUser.roleType,
			userId: relatedUser.id,
			[relatedUserServiceConfig.idFieldname]: parentId
		}
		await patchData(relatedUserServiceConfig.servicename, relatedUserToPatch).then(async () => {
			if (setRelatedUsers) {
				setRelatedUsers(await getRelatedUsersFromParent(relatedUserServiceConfig, parentId))
			}
		}).catch((error: Error) => {
			setToastParams({ title: 'Operative Rolle konnte nicht hinzugefügt werden', message: error.message })
		});
	})

}

export async function deleteRalatedUser(relatedUserId: number, relatedUserServiceConfig: RELATEDUSERSERVICECONFIG_ENTRY, parentId: number, setToastParams: Function, setRelatedUsers: Function) {
	await getRelatedUsersFromParent(relatedUserServiceConfig, parentId).then(async (relatedUsers: any[]) => {
		const relatedUser = relatedUsers.find((e: any) => e.id === relatedUserId)
		if (relatedUser) {
			const relatedUserToPatch = {
				id: relatedUser.relatedUserId,
				active: 0,
				version: relatedUser.version,
				userId: relatedUser.id,
				[relatedUserServiceConfig.idFieldname]: parentId
			}
			await patchData(relatedUserServiceConfig.servicename, relatedUserToPatch).then(async () => {
				if (setRelatedUsers) {
					setRelatedUsers(await getRelatedUsersFromParent(relatedUserServiceConfig, parentId))
				}
			})
		}
	}).catch((error: Error) => {
		setToastParams({ title: `Operative Rolle ${relatedUserId} konnte nicht inaktiviert werden`, message: error.message })
	});
}