import { parseISO } from "date-fns";
import { Tag } from "primereact/tag";
import { Button } from "primereact/button";
import { useEffect, useState } from "react";
import { Divider } from 'primereact/divider';
import { FieldInfo } from "../Components/FieldInfo";
import { OverlayPanelMenu } from "../Components/OverlayPanelMenu";
import { useDataViewFilterUpdate, useDataViewPagination, useDataViewPaginationUpdate } from "../Persistence/DataViewFilterContext";
import { ENUM_ROUTES } from "../Navigation/Routes";
import { useBreadcrumbsMinus, useBreadcrumbsUpdate } from "../Persistence/Breadcrumbs";
import { useConfigurationsList, useDataviewsConfig, useFBiBMode, useFormatDate } from "../Persistence/ConfigurationsContext";
import { useCurrentUser, useCurrentUserIsAdmin, useCurrentUserIsReader } from "../Persistence/CurrentUserContext";
import { ReminderDialog } from "../Reminders/ReminderDialog";
import { logDefault } from "../Utils/logger";
import { CompanyForm, emptyCompany, validateCompany } from "./CompanyForm";
import { ENUM_AWESOMEICON, ENUM_ICONS } from "../Components/Icons";
import { ConfirmDialog } from "../Components/ConfirmDialog";
import exportToPDF from "../Utils/exportToPDF";
import { ToastError } from "../Utils/ToastError";
import { CommentsModule } from "../Components/CommentsModule";
import { CompanyJobOpenings } from "./CompaniesJobOpenings";
import { CompanySheet } from "./CompanySheet";
import { RelatedUsersSheet } from "../RelatedUsers/RelatedUsersSheet";
import { useParams } from "react-router-dom";
import { BasicSheetLayout } from "../Components/Layouts/BasicSheetLayout";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";
import { ASSOCIATEDCONTACTCONFIG, AssociatedContactSheet } from "../AssociatedContacts/AssociatedContactSheet";
import { useFormik } from "formik";
import { EditDialog } from "../Components/Dialogs/EditDialog";
import { deleteCompany, getCompany, patchCompany, toggleCompanyStatus } from "./CompanyManager";
import { copyObject } from "../snippets";
import { EAACaseCreateWizard } from "../EAACase/EAACaseCreateWizard";
import { ENUM_DATAVIEWS_CONFIG } from "../Enums/ENUM_DATAVIEWS_CONFIG";
import { TRAININGCOURSE_SERVICECONFIG, TrainingCourseSheet } from "../FBiB/TrainingCourses/TrainingCourseSheet";
import { CompanyFBiBJobOpenings } from "./CompanyFBiBJobOpenings";
import { RELATEDUSERSERVICECONFIG } from '../RelatedUsers/RelatedUserManager';
import { FBiBCaseCreateWizard } from "../FBiB/FBiBCases/FBiBCaseCreateWizard";
import { Badge } from 'primereact/badge';

export const listStatus = [{ icon: 'fa fa-check-circle-o ', display: 'Aktiv', alias: 1, bgColor: 'color-tag-green' }, { icon: 'fa fa-times-circle-o', display: 'Inaktiv', alias: 0, bgColor: 'color-tag-grey' }];
const CLASSNAME = 'CompaniesData';

const SERVICENAME = ENUM_SERVICES.COMPANIES;
export const CompaniesData = () => {
	const formatDate = useFormatDate();
	const configurationsList = useConfigurationsList();
	const getDataviewConfig = useDataviewsConfig();
	const DATAVIEW_CONFIG = getDataviewConfig(ENUM_DATAVIEWS_CONFIG.EAACASES);
	const currentUser = useCurrentUser();
	const isAdmin = useCurrentUserIsAdmin();
	const isReader = useCurrentUserIsReader();
	const fbibMode = useFBiBMode();
	const { companyId } = useParams();
	const [company, setCompany] = useState(null);
	const [items, setItems] = useState([]);
	const [jobOpening, setJobOpening] = useState();
	const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);
	const [deleteDisplayConfirmDialog, setDeleteDisplayConfirmDialog] = useState(false);
	const [displayReminderDialog, setDisplayReminderDialog] = useState(false);
	const [displayDialog, setDisplayDialog] = useState(false);
	const dataViewFilterUpdate = useDataViewFilterUpdate();
	const dataViewPagination = useDataViewPagination();
	const setDataViewPagination = useDataViewPaginationUpdate();
	const updateBreadcrumbs = useBreadcrumbsUpdate();
	const minusBreadcrumbs = useBreadcrumbsMinus();
	const [toastParams, setToastParams] = useState(null);
	const [readonly, setReadonly] = useState(true);
	const [mounted, setMounted] = useState(false);

	const onUpdateCompany = async () => {
		if (companyId) {
			logDefault(CLASSNAME, 'info', 'onUpdateCompany companyId:' + companyId)
			await getCompany(companyId, isReader, setCompany, setReadonly, setToastParams)
		}
	}


	useEffect(() => {
		onUpdateCompany()
	}, [companyId])

	useEffect(() => {
		const cases = company && company.eaaCases && company.eaaCases.length > 0;
		const jobOpenings = company && company.jobOpenings && company.jobOpenings.length > 0;
		const fbibJobOpenings = company && company.fbibJobOpenings && company.fbibJobOpenings.length > 0;
		const items = isReader ? [] : [
			{ label: 'Bearbeiten', disabled: company && !company.active, icon: ENUM_ICONS.PENCIL, command: handleEditCompany },
		]
		items.push({ label: 'Exportieren', disabled: false, icon: ENUM_ICONS.EXPORT, command: handleExportCompany },
		)
		if (!isReader && company) {
			items.push({ separator: true })
			if (company.active) {
				items.push({ label: 'Inaktiv setzen', icon: ENUM_ICONS.DEACTIVATE, command: () => setDisplayConfirmDialog(true) })
			} else {
				items.push({ label: 'Aktiv setzen', icon: ENUM_ICONS.REACTIVATE, command: () => setDisplayConfirmDialog(true) })
			}
		}

		if (isAdmin && company) {
			items.push({ separator: true })
			items.push({ label: 'Unternehmen unwiderruflich löschen', className: 'eaa-menuitem-delete', icon: ENUM_ICONS.COMPANY_DELETE, disabled: (cases || jobOpenings || fbibJobOpenings), command: () => setDeleteDisplayConfirmDialog(true) })
		}
		setItems(items);
	}, [company])

	useEffect(() => {
		setMounted(true)
	}, [])

	const onClickEAACases = async (dataviewConfigName, route) => {
		const dataviewConfig = getDataviewConfig(dataviewConfigName)
		const filters = dataviewConfig.filters
		for (let key of Object.getOwnPropertyNames(filters)) {
			filters[key].value = filters[key].field === 'company.name' ? company.displayId : null
		}
		await dataViewFilterUpdate(dataviewConfig, filters);

		const paginationRows = dataviewConfig.pagination.paginationRows
		await setDataViewPagination(dataviewConfig, { paginationFirst: 0, paginationRows, currentPage: 0 })
		updateBreadcrumbs({ home: { icon: ENUM_ICONS.EAACASE }, items: [{ label: 'Fälle', route }] })
	}

	const handleEditCompany = () => {
		formikCompany.setValues(copyObject(company))
		setDisplayDialog(true);
	}

	const handleExportCompany = async () => {
		const companyExport = copyObject(company)
		for (let c of companyExport.contactPersons) {
			const type = configurationsList.ContactTypeList.find(e => e.alias === c.contactType)
			if (type) {
				c.contactType = type
			}
		}
		await exportToPDF('COMPANY', copyObject(companyExport), null, configurationsList.eaaModules);
	}

	const handleUseForReminder = () => {
		setDisplayReminderDialog(true)
	}

	/** formik Company */
	const formikCompany = useFormik({
		initialValues: JSON.parse(JSON.stringify(emptyCompany)),
		validate: validateCompany,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	const renderDialogs = () => {
		return <>
			<ToastError toastParams={toastParams} />
			<ConfirmDialog title={'Unternehmen ' + (company.active ? 'deaktivieren' : 'reaktivieren')} message={'Wollen Sie das Unternehmen ' + (company.active ? 'deaktivieren?' : 'reaktivieren?')} labelOk='Ja'
				handleOnClick={async () => await toggleCompanyStatus(company, currentUser, isReader, setCompany, setReadonly, setToastParams)}
				displayConfirmDialog={displayConfirmDialog} setDisplayConfirmDialog={setDisplayConfirmDialog} />
			<ConfirmDialog title={'Unternehmen unwiderruflich löschen'} message={'Wollen Sie das Unternehmen wirklich unwiderruflich löschen?\nAlle Informationen zu Adressen und Ansprechparter*innen diese Unternehmens werden auch gelöscht!'} labelOk='Ja'
				handleOnClick={async () => await deleteCompany(company, minusBreadcrumbs, setToastParams)}
				displayConfirmDialog={deleteDisplayConfirmDialog} setDisplayConfirmDialog={setDeleteDisplayConfirmDialog} />
			<ReminderDialog displayDialog={displayReminderDialog} setDisplayDialog={setDisplayReminderDialog} header='Unternehmen' name={company.name} serviceId={company.id} serviceName={ENUM_SERVICES.COMPANIES} userId={currentUser.id} />
			<EditDialog
				displayDialog={displayDialog}
				formik={formikCompany}
				labelButtonOk='Ändern'
				header='Unternehmensdetails ändern'
				message=''
				patchForm={async () => { await patchCompany(formikCompany.values, currentUser, isReader, setCompany, setReadonly, setToastParams) }}
				setDisplayDialog={setDisplayDialog}
				form={<CompanyForm
					formik={formikCompany}
					readOnly={false}
					handleSelectExistingCompany={onUpdateCompany}
				/>}
			/>
		</>
	}

	const renderContent = () => {
		const status = listStatus.find(entry => entry.alias === company.active);
		return <div className="eaa-maincontent flex flex-column">
			<div className='flex m-0 w-full h-full '>
				<div className='mx-auto eaa-maincontent flex flex-column'>
					<div className='flex flex-column'>
						<div className={' flex justify-content-end mb-4 gap-2 text-white'}>
							<Button onClick={handleEditCompany} icon={ENUM_ICONS.PENCIL} disabled={isReader || readonly} className={(fbibMode ? 'color-background-fbib' : 'color-background-blue') + ' flex text-white'} label='Bearbeiten' />
							{fbibMode ? <FBiBCaseCreateWizard parentCompanyId={companyId} ENUM_AWESOMEICON={ENUM_ICONS.FOLDER_PLUS} /> : <EAACaseCreateWizard company={company} icon={ENUM_ICONS.FOLDER_PLUS} ENUM_AWESOMEICON={ENUM_AWESOMEICON.FOLDER_PLUS} />}
							{company.countEaaCases === 0 ? '' : <div className={'button-basictitlelayout px-3 py-2 mr-2 cursor-pointer color-background-blue border-round-sm p-overlay-badge '} onClick={() => onClickEAACases(ENUM_DATAVIEWS_CONFIG.EAACASES, ENUM_ROUTES.EAACASES)}>
								<Badge value={company.countEaaCases} /><i className={'mr-2' + ENUM_ICONS.FOLDER_OPEN_O} /><span>{'Fälle des Unternehmens'}</span>
							</div>}
							{configurationsList.eaaModules.FBIB !== '1' || company.countFBiBCases === 0 ? '' :
								<div className={'button-basictitlelayout px-3 py-2 mr-2 cursor-pointer color-background-fbib border-round-sm p-overlay-badge '} onClick={() => onClickEAACases(ENUM_DATAVIEWS_CONFIG.FBIBCASES, ENUM_ROUTES.FBIB_CASES)}>
									<Badge value={company.countFBiBCases} /><i className={'mr-2' + ENUM_ICONS.FOLDER_OPEN_O} /><span>{'FBiB Fälle des Unternehmens'}</span>
								</div>
							}
							<Button onClick={handleUseForReminder} icon={ENUM_ICONS.CLOCK} disabled={isReader} className={(fbibMode ? 'color-background-fbib' : 'color-background-blue') + ' flex'} label='Wiedervorlage' />
						</div>
						<div className='flex grid'>
							<div className='col flex  flex-column  text-4xl '>
								<div className='flex text-center justify-content-center font-semibold'>
									{company.name}
								</div>
								<div className='flex text-center justify-content-center text-lg m-2 gap-2'>
									<div>{'' + company.displayId + ' erstellt am ' + (company.createdAt ? formatDate(parseISO(company.createdAt)) : '') + (company.creator ? (' von ' + company.creator.displayname) : '')}</div>
									<Tag className={'mr-2 text-white px-3 ' + (status.bgColor)} rounded value={status.display} />
								</div>
							</div>
						</div>
						<div className='flex card-container blue-container overflow-hidden my-3 grid'>
							<FieldInfo label='Telefon' content={company.phone} icon={<i className={ENUM_ICONS.PHONE + 'fa-2x'} />} />
							<FieldInfo label='Fax  ' content={company.fax} icon={<i className={ENUM_ICONS.FAX + 'fa-2x'} />} />
							<FieldInfo label='Mobil' content={company.mobile} icon={<i className={ENUM_ICONS.MOBILE + 'fa-3x'} />} />
							<FieldInfo label='E-Mail' content={<a href={`mailto:${company.mail}`}>{company.mail}</a>} icon={<i className={ENUM_ICONS.ENVELOPE + 'fa-2x'} />} />
						</div>
					</div>
					<Divider />
					<div className='flex flex-column surface-ground p-2 mx-2'>
						<div className='flex text-600 text-base mb-1'>
							Unternehmensbeschreibung
						</div>
						<div className='editor-changes-checked mx-4' dangerouslySetInnerHTML={{ __html: company.description }} />
					</div>
					<div className="flex flex-column mt-4 mr-3">
						<CommentsModule
							onUpdateComment={onUpdateCompany}
							key='companyCommentsModule'
							servicename={ENUM_SERVICES.COMPANYCOMMENTS}
							readonly={readonly}
							parentId={company.id}
							idFieldname='companyId'
							reminderParentService={ENUM_SERVICES.COMPANIES}
							comments={company.comments} />
					</div>
				</div>
			</div>
		</div>
	}

	const getSideSheets = () => {
		const sideSheets = [
			{
				index: 0, visible: true, icon: ENUM_ICONS.COMPANY + "fa-lg",
				component: <CompanySheet
					readonly={readonly}
					company={company}
					onUpdateCompany={onUpdateCompany}
					//onSelectCompany={onUpdateCompany}
					hideSpecialFields={true} />
			},
			{
				index: 1, visible: !fbibMode && configurationsList.eaaModules && configurationsList.eaaModules.JOBOPENING === '1', icon: ENUM_ICONS.JOBPROFILE + "fa-lg",
				component: <CompanyJobOpenings
					readonly={readonly}
					company={company}
					jobOpenings={jobOpening ? jobOpening : []}
					numCarousel={1}
					onUpdateCompany={onUpdateCompany}
					hideDivider={true} />
			},
			{
				index: 2, visible: fbibMode, icon: ENUM_ICONS.JOBPROFILE + "fa-lg",
				component: <CompanyFBiBJobOpenings
					readonly={readonly}
					company={company}
					numCarousel={1}
					onUpdateCompany={onUpdateCompany} />
			},
			{
				index: 3, visible: (false && configurationsList.eaaModules && configurationsList.eaaModules.FBIB === '1' && fbibMode), awesomeIcon: ENUM_AWESOMEICON.TRAININGCOURSES,
				component: <div className="flex flex-column row-gap-4">
					<TrainingCourseSheet
						serviceConfig={TRAININGCOURSE_SERVICECONFIG.COMPANY}
						readonly={readonly}
						parentId={companyId}
						trainingCoursesList={company.courses}
						updateParent={onUpdateCompany}
					/>
				</div>
			},
			{
				index: 4, visible: true, icon: ENUM_ICONS.CASE_USER + "fa-lg",
				component: <div className="flex flex-column row-gap-4">
					<RelatedUsersSheet readonly={readonly} key='relatedUserSheet' parentId={company.id} relatedUserServiceConfig={RELATEDUSERSERVICECONFIG.COMPANY} hideDivider={true} />
					<AssociatedContactSheet readonly={readonly} associatedContactServiceConfig={ASSOCIATEDCONTACTCONFIG.COMPANY} parent={company} />
				</div>
			},
		]
		return sideSheets;
	}

	return (!company ? '' :
		<BasicSheetLayout
			mainContent={<div className='flex mx-auto eaa-maincontent mt-0'>{!company ? '' : renderContent()}{!company ? '' : renderDialogs()}</div>}
			overlayMenu={<OverlayPanelMenu items={items} />}
			sideSheets={getSideSheets()}
			reminderService={SERVICENAME}
			serviceId={company.id}
		/>)

}
