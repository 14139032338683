import { format, parseISO } from 'date-fns';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ConfirmDialog } from '../Components/ConfirmDialog';
import { EditDialog } from '../Components/Dialogs/EditDialog';
import { FieldInfo } from "../Components/FieldInfo";
import { ENUM_ICONS } from '../Components/Icons';
import { BasicSheetTitleLayout } from '../Components/Layouts/BasicSheetTitleLayout';
import { OverlayPanelMenu } from '../Components/OverlayPanelMenu';
import { deleteData, patchData } from '../feathers';
import { useBreadcrumbsMinus } from '../Persistence/Breadcrumbs';
import { useConfigurationsList, useFormatDate } from '../Persistence/ConfigurationsContext';
import { useCurrentUser, useCurrentUserIsAdmin, useCurrentUserIsReaderEAA } from '../Persistence/CurrentUserContext';
import { logDefault } from '../Utils/logger';
import { ToastError } from '../Utils/ToastError';
import { hasParticipants, defaultActivity, validateActivity, ActivitiesInclusionOfficeForm } from './ActivitiesInclusionOfficeForm';
import { BasicSheetLayout } from '../Components/Layouts/BasicSheetLayout';
import { RelatedUsersSheet } from '../RelatedUsers/RelatedUsersSheet';
import { ENUM_SERVICES } from '../Enums/ENUM_SERVICES';
import { CommentsModule } from '../Components/CommentsModule';
import { ERROR_RESOURCENOTFOUND, useErrorUpdate } from '../Persistence/ErrorContext';
import { ENUM_ROUTES } from '../Navigation/Routes';
import { getActivityInclusionOffice, getTypeActivityObject } from './ActivitiesInclusionOfficeManager';
import { ReminderDialog } from '../Reminders/ReminderDialog';
import { ASSOCIATEDCONTACTCONFIG, AssociatedContactSheet } from '../AssociatedContacts/AssociatedContactSheet';
import { RELATEDUSERSERVICECONFIG } from '../RelatedUsers/RelatedUserManager';

const SERVICENAME = ENUM_SERVICES.ACTIVITIESINCLUSIONOFFICE;
const CLASSNAME = 'ActivitiesInclusionOfficeData'
export const ActivitiesInclusionOfficeData = () => {
	const formatDate = useFormatDate();
	const errorUpdate = useErrorUpdate();
	const currentUser = useCurrentUser();
	const isReader = useCurrentUserIsReaderEAA();
	const isAdmin = useCurrentUserIsAdmin()
	const configurationsList = useConfigurationsList();
	const { activityId } = useParams();
	const minusBreadcrumbs = useBreadcrumbsMinus();
	const [activity, setActivity] = useState();
	const [displayEditDialog, setDisplayEditDialog] = useState(false);
	const [displayDeleteConfirmDialog, setDisplayDeleteConfirmDialog] = useState(false);
	const [displayReminderDialog, setDisplayReminderDialog] = useState(false);
	const [readonly, setReadonly] = useState(true);
	const [toastParams, setToastParams] = useState(null);
	const navigate = useNavigate();

	useEffect(() => {
		if (!configurationsList?.eaaModules.ACTIVITIES_INCLUSIONOFFICE || !isAdmin) {
			errorUpdate('Modul Aktivitäten Inklusionsamt', 'Das Modul Aktivitäten Inklusionsamt ist für SIe nicht verfügbar.<br/>Wenden Sie sich bitte an Ihre/n Administrator*in')
			navigate('/' + ENUM_ROUTES.ERROR)
		}
	})

	const activityUpdate = async () => {
		if (activityId) {
			return await getActivityInclusionOffice(configurationsList, activityId).then((activity) => {
				setReadonly(isReader)
				setActivity(activity);
				logDefault(CLASSNAME, 'info', 'activity ', activity);
				return activity !== null
			}).catch((error) => {
				setToastParams({ title: 'Daten konnten nicht ermittelt werden', message: error.message })
			});
		}
	}

	useEffect(() => {
		setTimeout(async () => {
			if (!await activityUpdate()) {
				errorUpdate(ERROR_RESOURCENOTFOUND.title, ERROR_RESOURCENOTFOUND.message)
				navigate('/' + ENUM_ROUTES.ERROR)
			}
		}, 100);
	}, [errorUpdate, navigate])

	const patchActivity = async () => {
		await patchData(SERVICENAME, formik.values).then(async (patchedActivity) => {
			await activityUpdate();
		})
	}

	const onEditActivity = () => {
		formik.setValues({ ...activity, endDate: activity.endDate ? parseISO(activity.endDate) : null, recurring: activity.recurring === 1 })
		setDisplayEditDialog(true)
	}

	const deleteActivity = async () => {
		setDisplayDeleteConfirmDialog(false);
		await deleteData(SERVICENAME, activity.id).then(async () => {
			minusBreadcrumbs();
		}).catch((error) => {
			setToastParams({ title: 'Aktivität konnte nicht gelöscht werden', message: error.message })
		})
	}
	const renderItems = () => {
		const items = [{ label: 'Bearbeiten', icon: ENUM_ICONS.PENCIL, command: onEditActivity, disabled: readonly }]
		if (currentUser.permissions === 'ADMIN' && activity) {
			items.push({ label: 'Aktivität unwiderruflich löschen', className: 'eaa-menuitem-delete', icon: ENUM_ICONS.CASE_DELETE, command: () => setDisplayDeleteConfirmDialog(true) })
		}
		return items;
	}
	const formik = useFormik({
		initialValues: { ...defaultActivity, userId: currentUser.id },
		validate: validateActivity,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	const handleUseForReminder = () => {
		setDisplayReminderDialog(true)
	}

	const listButtons = [
		{ key: 'edit', label: 'Bearbeiten', icon: ENUM_ICONS.PENCIL, command: onEditActivity, disabled: readonly },
		{ key: 'reminder', label: 'Wiedervorlage', icon: ENUM_ICONS.CLOCK, command: handleUseForReminder, disabled: readonly },
	]

	const renderContent = () => {
		return (<>
			<div className='mx-auto eaa-maincontent'>
				<div>
					<BasicSheetTitleLayout
						listButtons={listButtons}
						title={activity.name}
						subtitle={activity.displayId + ' erstellt am ' + (activity.createdAt ? formatDate(parseISO(activity.createdAt)) : '') + (activity.creator ? (' von ' + activity.creator.displayname) : '')}
					/>

					<div className='flex card-container blue-container overflow-hidden grid'>
						<FieldInfo label='Typ' content={getTypeActivityObject(configurationsList, activity.typeActivity).display} className='col-6' />
						<FieldInfo label='Datum' content={activity.date ? formatDate(activity.date) : '---'} className='col-3' />
						{activity.typeActivity !== 'PUBLICRELATIONSMEASURES' ? '' : <FieldInfo label='Wiederkehrend bis' content={activity.endDate ? formatDate(parseISO(activity.endDate)) : '---'} className='col-3' />}
					</div>

					<div className='flex flex-column surface-ground p-2 mx-2'>
						<div className='flex text-600 text-base mb-1'>
							Beschreibung der Aktivität
						</div>
						<div className='editor-changes-checked mx-4' dangerouslySetInnerHTML={{ __html: activity.description }} />
					</div>
					<div className="flex flex-column mt-4 ml-2 mr-3">
						<CommentsModule
							onUpdateComment={activityUpdate}
							key='activityCommentsModule'
							servicename={ENUM_SERVICES.ACTIVITIESINCLUSIONOFFICECOMMENTS}
							readonly={readonly}
							parentId={activity.id}
							idFieldname='activitiesInclusionOfficeId'
							reminderParentService={ENUM_SERVICES.ACTIVITIESINCLUSIONOFFICE}
							comments={activity.comments} />
					</div>
				</div>
			</div></>
		)
	}
	const renderDialogs = () => {
		return <>
			<ToastError toastParams={toastParams} />
			<ConfirmDialog
				title='Aktivität des Inklusionsamts löschen'
				message={`<span>Wollen Sie die Aktivität des Inklusionsamts<br/><b>${activity.name}</b><br/>unwiederruflich löschen?<span>`}
				labelOk='Ja'
				handleOnClick={deleteActivity}
				displayConfirmDialog={displayDeleteConfirmDialog}
				setDisplayConfirmDialog={setDisplayDeleteConfirmDialog}
			/>
			<ReminderDialog
				displayDialog={displayReminderDialog}
				setDisplayDialog={setDisplayReminderDialog}
				header='Aktivität des Inklusionsamts'
				name={activity.name}
				serviceId={activity.id}
				serviceName={ENUM_SERVICES.ACTIVITIESINCLUSIONOFFICE}
				userId={currentUser.id}
			/>
			<EditDialog
				form={<ActivitiesInclusionOfficeForm formik={formik} key='activityinclusionoffice-form' />}
				formik={formik}
				patchForm={patchActivity}
				header={'Aktivität des Inklusionsamts bearbeiten'}
				message={'Hier können sie die Aktivität des Inklusionsamts bearbeiten'}
				setDisplayDialog={setDisplayEditDialog}
				displayDialog={displayEditDialog}
			/>
		</>
	}

	const getSideSheets = () => {
		const sideSheets = [
			{
				index: 0, visible: true, icon: ENUM_ICONS.CASE_USER + "fa-lg",
				component: <div className="flex flex-column row-gap-4">
					<RelatedUsersSheet
						readonly={readonly}
						key='relatedUserSheet'
						parentId={activity.id}
						relatedUserServiceConfig={RELATEDUSERSERVICECONFIG.ACTIVITY_INCLUSIONOFFICE}
						hideDivider={true} />
					<AssociatedContactSheet
						readonly={readonly}
						associatedContactServiceConfig={ASSOCIATEDCONTACTCONFIG.ACTIVITY_INCLUSIONOFFICE}
						parent={activity} />
				</div>
			},
		]
		return sideSheets;
	}

	return (!activity ? '' :
		<BasicSheetLayout
			mainContent={<div className='flex mx-auto eaa-maincontent mt-0'>{!activity ? '' : renderContent()}{!activity ? '' : renderDialogs()}</div>}
			overlayMenu={<OverlayPanelMenu items={renderItems()} />}
			sideSheets={getSideSheets()}
			reminderService={SERVICENAME}
			serviceId={activity.id}
		/>
	)
}