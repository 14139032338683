import { Tag } from "primereact/tag";
import { Divider } from "primereact/divider";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CaseEventsSheet } from "../CaseEvents/CaseEventsSheet";
import { CasePersonSheet } from "../CasePersons/CasePersonSheet";
import { CompanyChangeDialog } from "../Companies/CompanyChangeDialog";
import { CompanySheet } from "../Companies/CompanySheet";
import { FieldInfo } from "../Components/FieldInfo";
import { OverlayPanelMenu } from "../Components/OverlayPanelMenu";
import { deleteData } from "../feathers";
import { useCurrentUser, useCurrentUserIsAdmin, useCurrentUserIsReaderEAA } from "../Persistence/CurrentUserContext";
import { ReminderDialog } from "../Reminders/ReminderDialog";
import { EAACaseForm, emptyEAACase, validateEAACase } from "./EAACaseForm";
import { ToastError } from "../Utils/ToastError";
import { ConfirmDialog } from "../Components/ConfirmDialog";
import { ENUM_AWESOMEICON, ENUM_AWESOMEICON_SIZE, ENUM_ICONS, Icon } from "../Components/Icons";
import exportToPDF from "../Utils/exportToPDF";
import { useConfigurationsList, useDataviewsConfig, useFBiBMode, useFormatDate } from "../Persistence/ConfigurationsContext";
import { useBreadcrumbsUpdate } from "../Persistence/Breadcrumbs";
import { BasicSheetLayout } from "../Components/Layouts/BasicSheetLayout";
import { BasicSheetTitleLayout } from "../Components/Layouts/BasicSheetTitleLayout";
import { logDefault } from "../Utils/logger";
import { ENUM_ROUTES, ROUTES } from "../Navigation/Routes";
import { ERROR_RESOURCENOTFOUND, useErrorUpdate } from "../Persistence/ErrorContext";
import { RelatedUsersSheet } from "../RelatedUsers/RelatedUsersSheet";
import { getEAACase, patchEAACase } from "./EAACaseManager";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";
import { CaseRequestSheet } from "../CaseRequests/CaseRequestSheet";
import { ASSOCIATEDCONTACTCONFIG, AssociatedContactSheet } from "../AssociatedContacts/AssociatedContactSheet";
import { SplitButton } from 'primereact/splitbutton';
import { EditDialog } from "../Components/Dialogs/EditDialog";
import { useFormik } from "formik";
import { copyObject } from "../snippets";
import { Badge } from "primereact/badge";
import { RELATEDUSERSERVICECONFIG } from '../RelatedUsers/RelatedUserManager';
import { ENUM_DATAVIEWS_CONFIG } from "../Enums/ENUM_DATAVIEWS_CONFIG";
import { useDataViewFilterUpdate, useDataViewPaginationUpdate } from "../Persistence/DataViewFilterContext";

const CLASSNAME = 'EAACaseData';
const SERVICENAME = ENUM_SERVICES.EAACASES;
export const EAACaseData = () => {
	const navigate = useNavigate();
	const getDataviewConfig = useDataviewsConfig();
	const location = useLocation();
	const formatDate = useFormatDate();
	const errorUpdate = useErrorUpdate();
	const currentUser = useCurrentUser();
	const isReader = useCurrentUserIsReaderEAA();
	const isAdmin = useCurrentUserIsAdmin();
	const configurationsList = useConfigurationsList();
	const updateBreadcrumbs = useBreadcrumbsUpdate();
	const fbibMode = useFBiBMode();
	const dataViewFilterUpdate = useDataViewFilterUpdate();
	const setDataViewPagination = useDataViewPaginationUpdate();
	const { eaaCaseId } = useParams();
	const [eaaCase, setEaaCase] = useState();
	const [readonly, setReadonly] = useState(true);
	const [mounted, setMounted] = useState(false);
	const [displayDeleteConfirmDialog, setDisplayDeleteConfirmDialog] = useState(false);
	const [displayReminderDialog, setDisplayReminderDialog] = useState(false);
	const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);
	const [displayCompanyDialog, setDisplayCompanyDialog] = useState(false);
	const [displayEaaCaseDialog, setDisplayEAACaseDialog] = useState(false);
	const [toastParams, setToastParams] = useState(null);
	const [changeCompany, setChangeCompany] = useState(false);
	const [favoriteContactPersons, setFavoriteContactPersons] = useState()
	const [favoriteContactPersonsInitialized, setFavoriteContactPersonsInitialized] = useState(false)

	const eaaCaseUpdate = async () => {
		if (eaaCaseId) {
			return await getEAACase(eaaCaseId, configurationsList, isReader, setReadonly, setEaaCase, setToastParams, setFavoriteContactPersons)
		}
	}

	useEffect(() => {
		if (mounted) {
			setTimeout(async () => {
				logDefault(CLASSNAME, 'info', 'eaaCaseUpdate in useEffect.setTimeout')
				if (!await eaaCaseUpdate()) {
					errorUpdate(ERROR_RESOURCENOTFOUND.title, ERROR_RESOURCENOTFOUND.message)
					navigate('/' + ENUM_ROUTES.ERROR)
				}
			}, 10);
		}
	}, [mounted])

	useEffect(() => {
		setMounted(true)
	}, [])

	useEffect(() => {
		if (eaaCase && favoriteContactPersonsInitialized) {
			eaaCase.favoriteContactPersons = favoriteContactPersons
			handlePatchEaaCase(eaaCase)
		} else if (eaaCase) {
			setFavoriteContactPersonsInitialized(true)
		}

	}, [favoriteContactPersons])

	const handlePatchEaaCase = async (eaaCase) => {
		await patchEAACase(eaaCase, configurationsList, isReader, setReadonly, setEaaCase, setToastParams, setFavoriteContactPersons)
	}
	const handleExportEAACase = async () => {
		const eaaCaseCopy = copyObject(eaaCase)
		const status = configurationsList.CaseStatusList.find((e) => e.alias === eaaCaseCopy.status);
		const initiatedBy = configurationsList.InitiatedByTypesList.find((e) => e.alias === eaaCaseCopy.initiatedBy)
		await exportToPDF('EAACASE', { ...eaaCaseCopy, initiatedBy, status }, copyObject(configurationsList.CaseEventClassifierStructure), configurationsList.eaaModules);
	}

	const onClickEAACases = async (dataviewConfigName, route) => {
		const dataviewConfig = getDataviewConfig(dataviewConfigName)
		const filters = dataviewConfig.filters
		for (let key of Object.getOwnPropertyNames(filters)) {
			filters[key].value = filters[key].field === 'company.name' ? 'UN-' + eaaCase?.companyId.toString().padStart(5, '0') : null
		}
		await dataViewFilterUpdate(dataviewConfig, filters);

		const paginationRows = dataviewConfig.pagination.paginationRows
		await setDataViewPagination(dataviewConfig, { paginationFirst: 0, paginationRows, currentPage: 0 })
		updateBreadcrumbs({ home: { icon: ENUM_ICONS.EAACASE }, items: [{ label: 'Fälle', route }] })
	}

	const handleEditEaaCase = async () => {
		formikEAACase.setValues(await getEAACase(eaaCaseId, configurationsList));
		setDisplayEAACaseDialog(true)
	}
	const handleDeleteEaaCase = async () => {
		setDisplayDeleteConfirmDialog(false);
		await deleteData('eaa-cases', eaaCase.id).then(async () => {
			updateBreadcrumbs({ home: { icon: ENUM_ICONS.EAACASE }, items: [{ label: ROUTES.EAACASES.label, route: `${ENUM_ROUTES.EAACASES}/` }] })
		}).catch((error) => {
			setToastParams({ title: 'Fall konnte nicht gelöscht werden', message: error.message })
		})
	}
	const handlerChangeCompany = () => {
		setChangeCompany(true);
		setDisplayCompanyDialog(true)
	}

	const changeCompanyId = async (companyId) => {
		eaaCase.companyId = companyId;
		await handlePatchEaaCase(eaaCase).then(() => {
			navigate(location.pathname)
		});
	}

	const renderSummaryContacts = () => {
		const summary = [
			{ label: 'Erstkontakt', value: (eaaCase.firstContact ? 'Ja' : 'nein') },
			{ label: 'Anzahl Kontakte', value: (eaaCase.typeCONTACT) },
			{ label: 'Betriebsbesuch', value: (eaaCase.G1) },
			{ label: 'Ergebnis', value: (eaaCase.typeCONTACTRESULT) },
			{ label: 'Nachhaltigkeit', value: (eaaCase.typeSUSTAINABILITY) }
		]

		return <div className='flex gap-5  mx-4'>
			{summary.map((e) => <div>{`${e.label}: `}<b>{e.value}</b> </div>)}
		</div>
	}

	const renderDocLink = () => {
		let docLink = '';
		if (eaaCase && eaaCase.documentLocation) {

			try {
				const url = new URL(eaaCase.documentLocation);
				if (url.protocol === "http:" || url.protocol === "https:") {
					docLink = eaaCase.documentLocation
				} else {
					docLink = 'file:///' + eaaCase.documentLocation;
				}
			} catch (error) { }
		}
		return <a href={docLink} target="_blank" rel="noreferrer" >{docLink}</a>

	}

	/** formik EaaCase */
	const formikEAACase = useFormik({
		initialValues: JSON.parse(JSON.stringify(emptyEAACase)),
		validate: validateEAACase,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	const renderDialogs = () => {
		return <>
			<ConfirmDialog title='Status ändern' message={'Fall' + (readonly ? ' aktivieren?' : ' inaktivieren?')} labelOk='Ja'
				handleOnClick={async () => await handlePatchEaaCase({ ...eaaCase, status: (eaaCase.status.alias || eaaCase.status) === 'INACTIVE' ? 'OPEN' : 'INACTIVE' })}
				displayConfirmDialog={displayConfirmDialog} setDisplayConfirmDialog={setDisplayConfirmDialog} />
			<ConfirmDialog title='Fall löschen' message={'Wollen Sie den Fall wirklich unwiderruflich löschen?\nAlle angelegten Kontakte zu diesem Fall werden ebenfalls gelöscht.'} labelOk='Ja'
				handleOnClick={handleDeleteEaaCase} displayConfirmDialog={displayDeleteConfirmDialog} setDisplayConfirmDialog={setDisplayDeleteConfirmDialog} />

			<ToastError toastParams={toastParams} />
			<ReminderDialog
				displayDialog={displayReminderDialog}
				setDisplayDialog={setDisplayReminderDialog}
				header='Fall'
				name={eaaCase.name + ' - ' + eaaCase.company.name}
				serviceId={eaaCase.id}
				serviceName={ENUM_SERVICES.EAACASES}
				userId={currentUser.id} />
			<EditDialog
				displayDialog={displayEaaCaseDialog}
				form={<EAACaseForm formik={formikEAACase} />}
				formik={formikEAACase}
				header='Falldetails ändern'
				labelButtonOk={'Ändern'}
				message='Hier können Sie die Details eines Falles ändern.'
				patchForm={() => { handlePatchEaaCase(formikEAACase.values) }}
				setDisplayDialog={setDisplayEAACaseDialog}
			/>

			<CompanyChangeDialog
				companyId={eaaCase.companyId}
				displayCompanyDialog={displayCompanyDialog}
				setDisplayCompanyDialog={setDisplayCompanyDialog}
				handleOnCompanyPatched={eaaCaseUpdate}
				changeCompanyId={changeCompanyId} />
		</>
	}

	const listButtons = [
		{ key: 'edit', label: 'Bearbeiten', icon: ENUM_ICONS.PENCIL, command: handleEditEaaCase, disabled: isReader || readonly },
		{
			key: 'eaacases',
			component: <div className={'button-basictitlelayout px-3 py-2 mr-2 cursor-pointer color-background-blue text-white border-round-sm p-overlay-badge '} onClick={() => onClickEAACases(ENUM_DATAVIEWS_CONFIG.EAACASES, ENUM_ROUTES.EAACASES)}>
				<Badge value={eaaCase?.countEaaCases} /><i className={'mr-2' + ENUM_ICONS.FOLDER_OPEN_O} /><span>{'Fälle des Unternehmens'}</span>
			</div>,
			disabled: false
		},
		{
			key: 'fbibcases',
			component: <div className={'button-basictitlelayout px-3 py-2 mr-2 cursor-pointer color-background-fbib text-white border-round-sm p-overlay-badge '} onClick={() => onClickEAACases(ENUM_DATAVIEWS_CONFIG.FBIBCASES, ENUM_ROUTES.FBIB_CASES)}>
				<Badge value={eaaCase?.countFBiBCases} /><i className={'mr-2' + ENUM_ICONS.FOLDER_OPEN_O} /><span>{'FBiB Fälle des Unternehmens'}</span>
			</div>,
			hidden: configurationsList.eaaModules.FBIB !== '1' || eaaCase?.countFBiBCases === 0
		},
		{ key: 'reminder', label: 'Wiedervorlage', icon: ENUM_ICONS.CLOCK, command: () => setDisplayReminderDialog(true), disabled: isReader }
	]

	const renderContent = () => {
		const maxChar = 200;
		const title = eaaCase ? eaaCase.company.name + eaaCase.name : '';
		const status = configurationsList.CaseStatusList.find((e) => e.alias === eaaCase.status);
		const initiatedBy = configurationsList.InitiatedByTypesList.find((e) => e.alias === eaaCase.initiatedBy)
		const items = configurationsList.CaseStatusList.map((caseStatus) => {
			return { label: caseStatus.display, alias: caseStatus.alias, command: async (e) => await handlePatchEaaCase({ ...eaaCase, status: caseStatus.alias }) }
		}).filter((e) => eaaCase.status.alias !== e.alias)
		return <div className="eaa-maincontent flex flex-column " >
			<BasicSheetTitleLayout
				listButtons={listButtons}
				title={eaaCase ? `${(title.length > maxChar ? eaaCase.name.substring(0, maxChar - eaaCase.company.name.length) + '...' : eaaCase.name)}` : ''}
				subtitle={<div id='spitbutton-eaacase' className="flex align-items-center gap-2"><div className='flex'>{eaaCase ? `${eaaCase.displayId} vom ${formatDate(eaaCase.date)} ${(eaaCase.creator ? (' von ' + eaaCase.creator.displayname) : '')}` : ''}</div>
					{isReader ? <Tag className={'mr-2 text-white px-3 color-tag-' + status.color} rounded value={status.display} /> :
						<SplitButton disabled={isReader || readonly} buttonClassName="text-sm text-white py-0 px-2" className={'mr-2  text-white p-button-text p-button-rounded p-0 color-tag-' + status.color} label={status.display} model={items}></SplitButton>}
				</div>} />

			<div className='flex flex-column my-4' >
				<div className='flex flex-wrap justify-content-between grid'>
					<FieldInfo label='Unternehmen' content={eaaCase.company.name} className='col-6' />
					<FieldInfo label='Verantwortliche*r' content={eaaCase.responsible ? eaaCase.responsible.displayname : 'n.n.'} className='col-3' />
					<FieldInfo label='Ersteller*in' content={eaaCase.creator.displayname} className='hidden col-3' />
				</div>
				<div className='flex flex-wrap justify-content-between  grid'>
					<FieldInfo label='Initiiert durch' content={initiatedBy.display} className='col-3' />
					<FieldInfo label='Dateipfad Dokumente' content={renderDocLink()} className='col-9' />
				</div>
				<Divider />

				<div className='flex flex-column surface-ground p-2'>
					<div className='flex text-600 text-base mb-1'>
						Erläuterung zum Fall
					</div>
					<div className='editor-changes-checked mx-4' dangerouslySetInnerHTML={{ __html: eaaCase.description }} />
				</div>

				<div className='flex flex-column surface-ground p-2'>
					<div className='flex text-600 text-base mb-2'>
						Zusammenfassung der Kontakte
					</div>
					{renderSummaryContacts()}
				</div>
				<div className='flex text-1xl mx-2'>
					{eaaCase ? <CaseEventsSheet eaaCase={eaaCase} caseEvents={eaaCase.caseEvents} eaaCaseUpdate={eaaCaseUpdate} readonly={readonly} /> : ''}
				</div>
			</div>
		</div>
	}
	//<div className={showChangeCompany && isAdmin ? 'flex w-1rem ' : 'hidden'} onClick={handlerChangeCompany} ><Icon ENUM_AWESOMEICON={ENUM_AWESOMEICON.CHANGECOMPANY} size={ENUM_AWESOMEICON_SIZE.X1} /></div>
	const renderItems = () => {
		const items = [{ label: 'Bearbeiten', icon: ENUM_ICONS.PENCIL, command: handleEditEaaCase, disabled: isReader || readonly },
		{ label: 'Exportieren', disabled: false, icon: ENUM_ICONS.EXPORT, command: handleExportEAACase },
		{ label: 'Unternehmen wechseln', disabled: !isAdmin || readonly, icon: <Icon ENUM_AWESOMEICON={ENUM_AWESOMEICON.CHANGECOMPANY} size={ENUM_AWESOMEICON_SIZE.X1} className='mr-1' />, command: handlerChangeCompany },
		{ separator: true },
		{ label: (readonly ? 'Aktiv setzen' : 'Inaktiv setzen'), icon: (readonly ? ENUM_ICONS.REACTIVATE : ENUM_ICONS.DEACTIVATE), command: () => setDisplayConfirmDialog(true), disabled: isReader }]
		if (currentUser.permissions === 'ADMIN' && eaaCase) {
			items.push({ separator: true })
			items.push({ label: 'Fall unwiderruflich löschen', className: 'eaa-menuitem-delete', icon: ENUM_ICONS.CASE_DELETE, command: () => setDisplayDeleteConfirmDialog(true) })
		}
		return <OverlayPanelMenu items={items} />;
	}

	const getSideSheets = () => {
		const amountCaseRequests = eaaCase.caseRequests.length
		const colorBadgeCaseRequests = amountCaseRequests === 0 ? 'danger' : (eaaCase.caseRequests.find(e => e.status.alias === 'INPROGRESS') ? 'warning' : 'success')
		const sideSheets = [
			{
				index: 0, visible: true, icon: ENUM_ICONS.COMPANY + "fa-lg",
				component: <CompanySheet
					readonly={readonly} c
					company={eaaCase.company}
					onUpdateCompany={eaaCaseUpdate}
					showChangeCompany={true}
					favoriteContactPersons={favoriteContactPersons}
					setFavoriteContactPersons={setFavoriteContactPersons} />
			},
			{ index: 1, visible: true, icon: ENUM_ICONS.USER + "fa-lg", component: <CasePersonSheet readonly={readonly} eaaCase={eaaCase} patchEaaCase={handlePatchEaaCase} /> },
			{
				index: 2, visible: true, icon: ENUM_ICONS.CASE_USER + "fa-lg",
				component: <div className="flex flex-column row-gap-4">
					<RelatedUsersSheet readonly={readonly} key='relatedUserSheet' parentId={eaaCase.id} relatedUserServiceConfig={RELATEDUSERSERVICECONFIG.CASE} hideDivider={true} />
					<AssociatedContactSheet readonly={readonly} associatedContactServiceConfig={ASSOCIATEDCONTACTCONFIG.CASE} parent={eaaCase} />
				</div>
			},
			{
				index: 3,
				visible: true,
				awesomeIcon: ENUM_AWESOMEICON.CASEREQUESTS,
				component: <CaseRequestSheet eaaCase={eaaCase} readonly={readonly} updateEaaCase={eaaCaseUpdate} />,
				badge: <Badge value={amountCaseRequests} severity={colorBadgeCaseRequests} style={{ right: '15px', }} className="z-2 relative flex" />
			},

		]
		return sideSheets;
	}

	return (!eaaCase ? '' :
		<BasicSheetLayout
			mainContent={<div className='flex mx-auto eaa-maincontent mt-0'>{!eaaCase ? '' : renderContent()}{!eaaCase ? '' : renderDialogs()}</div>}
			overlayMenu={renderItems()}
			sideSheets={getSideSheets()}
			reminderService={SERVICENAME}
			serviceId={eaaCase.id}
		/>
	)
}
