import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";
import { useEffect, useState } from 'react';
import { AssociatedContactCard } from "./AssociatedContactCard";
import { ENUM_ICONS } from "../Components/Icons";
import { ToastError } from "../Utils/ToastError";
import { ConfirmDialog } from "../Components/ConfirmDialog";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Card } from "primereact/card";
import { Dropdown } from "primereact/dropdown";
import { addAssociatedContact, deleteAssociatedContact, getAssociatedContactsFromParent } from "./AssociatedContactsManager";
import { useAssociatedContactsSelectionList } from '../Persistence/AssociatedContactsContext';
import { useConfigurationsList } from "../Persistence/ConfigurationsContext";
import { getData } from "../feathers";
import { AssociatedContactChooseDialog } from "./AssociatedContactChooseDialog";
import { useFormik } from "formik";
import { EditDialog } from "../Components/Dialogs/EditDialog";

export const ASSOCIATEDCONTACTCONFIG = {
	ASSOCIATEDORGANIZATION: { servicename: ENUM_SERVICES.ASSOCIATEDORGANIZATIONS, idFieldname: 'id', displayname: 'Organisation', deleteMessage: 'von der Organisation', editMessage: 'in der Organisation' },
	CASE: { servicename: ENUM_SERVICES.EAACASES, idFieldname: 'eaaCaseId', displayname: 'Falls', deleteMessage: 'vom Fall', editMessage: 'im Fall' },
	COMPANY: { servicename: ENUM_SERVICES.COMPANIES, idFieldname: 'companyId', displayname: 'Unternehmens', deleteMessage: 'vom Unternehmen', editMessage: 'des Unternehmens' },
	PROVIDERACTIVITY: { servicename: ENUM_SERVICES.PROVIDERACTIVITIES, idFieldname: 'provideractivityId', displayname: 'Trägeraktivität', deleteMessage: 'aus der Trägeraktivität', editMessage: 'der Trägeraktivität' },
	ACTIVITY_INCLUSIONOFFICE: { servicename: ENUM_SERVICES.ACTIVITIESINCLUSIONOFFICE, idFieldname: 'activitiesInclusionOfficeId', displayname: 'Aktivität des Inklusionsamts', deleteMessage: 'aus der Aktivität des Inklusionsamts', editMessage: 'der Aktivität des Inklusionsamts' },
	FBIBCASE: { servicename: ENUM_SERVICES.FBIBCASES, idFieldname: 'fbibCaseId', displayname: 'FBiB Falls', deleteMessage: 'vom FBiB Fall', editMessage: 'im FBiB Fall' },
	FBIBPROVIDERACTIVITY: { servicename: ENUM_SERVICES.FBIBPROVIDERACTIVITIES, idFieldname: 'fbibProvideractivityId', displayname: 'Trägeraktivität', deleteMessage: 'aus der Trägeraktivität', editMessage: 'der Trägeraktivität' },
}

const SERVICENAME = ENUM_SERVICES.ASSOCIATEDCONTACTS;
const CLASSNAME = 'AssociatedContactSheet'
export const AssociatedContactSheet = ({ onAssociatedContactPatched, readonly, associatedContactServiceConfig, parent }) => {

	const configurationsList = useConfigurationsList();
	const associatedContactsSelectionList = useAssociatedContactsSelectionList();
	const [associatedContact, setAssociatedContact] = useState();
	const [associatedContacts, setAssociatedContacts] = useState([]);
	const [associatedContactId, setAssociatedContactId] = useState();
	const [displayAssociatedContactDialog, setDisplayAssociatedContactDialog] = useState(false);
	const [displayConfirmDialogDelete, setDisplayConfirmDialogDelete] = useState(false);
	const [toastParams, setToastParams] = useState(null);

	useEffect(() => {
		getAssociatedContactsFromParent(associatedContactServiceConfig, parent.id).then((result) => {
			setAssociatedContacts(result)
		})
	}, [])

	const onAssociatedContactDelete = async () => {
		await deleteAssociatedContact(associatedContact.id, associatedContactServiceConfig, parent.id).then(async (associatedContacts) => {
			setAssociatedContacts(associatedContacts)
			if (onAssociatedContactPatched) {
				await onAssociatedContactPatched();
			}
		}).catch((error) => {
			setToastParams({ title: 'Netzwerkkontakt konnte nicht gelöscht werden', message: error.message })
		});
	}

	const confirmAssociatedContactDelete = async (associatedContact) => {
		setAssociatedContact(associatedContact);
		setDisplayConfirmDialogDelete(true)
	}

	const confirmAssociatedContactAdd = async () => {
		await getData(SERVICENAME, formik.values.associatedContactId).then(async (associatedContact) => {
			await addAssociatedContact(associatedContact, associatedContactServiceConfig, parent.id).then(async (associatedContacts) => {
				setAssociatedContacts(associatedContacts)
				setDisplayAssociatedContactDialog(false);
				if (onAssociatedContactPatched) {
					await onAssociatedContactPatched();
				}
			})
		})
	}

	const renderHeader = () => {
		return <div className='flex flex-column'>
			<div className={'flex justify-content-between border-bottom-1'}>
				<div className='flex font-medium  mb-2 '>Netzwerkkontakte</div>
				<div className='flex '>
					<div className={readonly ? 'hidden' : 'flex w-1rem open-icon'} onClick={() => { formik.setFieldValue('associatedContactId', null); setDisplayAssociatedContactDialog(true) }}><i className={ENUM_ICONS.PLUS_CIRCLE} /></div>
				</div>
			</div>
		</div>
	}

	const renderAssociatedContacts = () => {
		return !associatedContacts ? 'keine' : <div className='flex flex-column gap-1 w-full'> {associatedContacts.map((associatedContact) => {
			return <AssociatedContactCard
				key={'associatedcontactcard_' + associatedContact.id}
				associatedContact={associatedContact}
				onDeleteHandler={confirmAssociatedContactDelete}
				readonly={readonly} />
		})}</div>
	}

	/** formik EaaCase */
	const formik = useFormik({
		initialValues: { associatedContactId: null },
		validate: () => { },
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	return (
		<>
			<ToastError toastParams={toastParams} />
			<ConfirmDialog title={'Netzwerkkontakt entfernen'} message={`<span>Sind Sie sich sicher, den Netzwerkkontakt <br/><b>${associatedContact ? associatedContact.name : ''}</b><br/>zu entfernen?</span>`} labelOk='Ja'
				handleOnClick={onAssociatedContactDelete}
				displayConfirmDialog={displayConfirmDialogDelete}
				setDisplayConfirmDialog={setDisplayConfirmDialogDelete} />
			<EditDialog
				displayDialog={displayAssociatedContactDialog}
				form={<AssociatedContactChooseDialog
					associatedContacts={associatedContacts}
					key='AssociatedContactChooseDialog'
					formik={formik}
					readonly={readonly}
					className={'p-0 m-0'}
				/>}
				formik={formik}
				labelButtonOk='hinzufügen'
				message={'Hier können Sie einen Netzwerkkontakt suchen und hinzufügen.'}
				patchForm={confirmAssociatedContactAdd}
				setDisplayDialog={setDisplayAssociatedContactDialog}
				header={'Netzwerkkontakt'}
			/>

			{configurationsList.eaaModules.ASSOCIATEDCONTACTS !== '1' ? '' : <Card className={"associatedcontactsheet card-sheet"} header={renderHeader}>
				{renderAssociatedContacts()}
			</Card>}
		</>
	)

}